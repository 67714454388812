import abiyeBig from '../assets/images/about-img/abiye-big.svg';
import abiyeSmall from '../assets/images/about-img/abiye-small.svg';
import andrewBig from '../assets/images/about-img/andrew-big.svg';
import andrewSmall from '../assets/images/about-img/andrew-small.svg';
import christianBig from '../assets/images/about-img/christian-big.svg';
import christianSmall from '../assets/images/about-img/christian-small.svg';
import jamesBig from '../assets/images/about-img/james-big.svg';
import jamesSmall from '../assets/images/about-img/james-small.svg';
import lisaBig from '../assets/images/about-img/lisa-big.svg';
import lisaSmall from '../assets/images/about-img/lisa-small.svg';
import nadayarBig from '../assets/images/about-img/nadayar-big.svg';
import nadayarSmall from '../assets/images/about-img/nadayar-small.svg';
import olaleyeBig from '../assets/images/about-img/olaleye-big.svg';
import olaleyeSmall from '../assets/images/about-img/olaleye-small.svg';
import ololadeBig from '../assets/images/about-img/ololade-big.svg';
import ololadeSmall from '../assets/images/about-img/ololade-small.svg';
import onyemaBig from '../assets/images/about-img/onyema-big.svg';
import onyemaSmall from '../assets/images/about-img/onyema-small.svg';
// big - 320px
// small - 210px
const partners = [
    {
        id: 1,
        name: 'Christian Reuben',
        title: 'Lead Partner',
        desc: 'Christian has 15+ years of experience within CX, Advisory, Strategy, Learning Development, Operations, Finance, Training. Recent work focus has been centred around Strategy Development, Leadership Development, Systems Engineering, Executive Advisory, and driving Operational Improvements through Technology, Digitisation, + People Development.',
        thumbnail: christianSmall,
        mainImg: christianBig,
        linkedin: 'https://www.linkedin.com/in/christianreuben/',
        twitter: 'https://x.com/xtianreuben',
    },

    {
        id: 2,
        name: 'Onyema Nwigwe',
        title: 'VC Fundraising Partner',
        desc: 'Onyema is a venture capital investor at AfricInvest Group who has experience investing in several start-ups, including 54gene, PalmPay, Migo, and KaiOS Technologies. He has a background in political science and has also studied international business negotiations and European business strategy. He is a recipient of the Davis Scholarship and the Finstad Entrepreneurial Grant.',
        thumbnail: onyemaSmall,
        mainImg: onyemaBig,
        linkedin: 'https://www.linkedin.com/in/justiceonwigwe/',
        twitter: 'https://x.com/onyemanj',
    },
    {
        id: 3,
        name: 'Ololade Olanipekun',
        title: 'Big Data Partner',
        desc: 'Onyema is an experienced venture capital investor and advisor with a background in political science, international business negotiations, and European business strategy. He has invested in several start-ups, including 54gene, PalmPay, Migo, and KaiOS Technologies. He has experience not just in investing rounds as a venture capitalist, but also as a highly credible advisor to technology start-ups in Africa looking to fundraise. Onyema is a Davis Scholar and a recipient of the Finstad Entrepreneurial Grant.',
        thumbnail: ololadeSmall,
        mainImg: ololadeBig,
        linkedin: 'https://www.linkedin.com/in/ololadeolanipekun/',
        twitter: 'https://x.com/ololade_b_o',
    },

    {
        id: 4,
        name: 'Abiye Levy-Braide',
        title: 'Technology Partner',
        desc: 'Abiye has many years of experience as a CTO + Project Engineer with experience in Industrial Digitisation. He has vast experience with building MVPs, launching and taking them to market. In addition to Saas and technology product development, Abiye has expertise in P.M., Software, Agile and Lean Systems.',
        thumbnail: abiyeSmall,
        mainImg: abiyeBig,
        linkedin: 'https://www.linkedin.com/in/levybraide/',
        twitter: 'https://x.com/levybuble',
    },

    {
        id: 5,
        name: 'James Adeniji PhD',
        title: 'Research Partner',
        desc: 'James is part of an influential market insight think tank, helping companies understand their position in the market, mapping out opportunities, and planning for the ever-changing macro and consumer behaviour dynamics. James has co-authored case studies and other publications in areas of Finance, Strategy and Marketing.',
        thumbnail: jamesSmall,
        mainImg: jamesBig,
        linkedin: 'https://www.linkedin.com/in/james-adeniji-9a314a57/',
        twitter: '',
    },
];

const advisory = [
    {
        id: 1,
        name: 'Andrew Olaleye',
        title: 'Ex. Mckinsey(5yrs) / Harvard MBA',
        desc: '15+ years of experience within Customer Experience, Strategy, Operations and Finance. Andrew has worked across North America, Europe, and Sub-Saharan Africa. Recent work focus has been centred around driving operational improvements through technology and digitisation.',
        thumbnail: olaleyeSmall,
        mainImg: olaleyeBig,
        linkedin: 'https://www.linkedin.com/in/andrew-olaleye/',
        twitter: 'https://x.com/AndrewOlaleye',
    },

    {
        id: 2,
        name: 'Nadayar Enegesi',
        title: 'Co-founder Andela and Eden Life',
        desc: 'Nadayar is co-founder and CEO at Eden Life, a tech-enabled services company that automates home chores. Driven by a passion for technology, Nadayar had previously co-founded Fora + Andela. He has a proven track record of building enterprise software.',
        thumbnail: nadayarSmall,
        mainImg: nadayarBig,
        linkedin: 'https://www.linkedin.com/in/nadayar/',
        twitter: 'https://x.com/nadayar',
    },

    {
        id: 3,
        name: 'Andrew Garza',
        title: 'Ex. Bain(5yrs) / Stanford MBA',
        desc: 'Andrew is Co-Founder and COO of Lifestores Pharmacy, a chain of mass-market pharmacies in Nigeria. Previously, he was the Senior Manager of Supply Chain & Business Development at CHI Pharmaceuticals in Lagos, Nigeria. He received an MBA from Stanford University and a BA in Sociology/Economics from Haverford College.',
        thumbnail: andrewSmall,
        mainImg: andrewBig,
        linkedin: 'https://www.linkedin.com/in/andrewgarza/',
        twitter: '',
    },

    {
        id: 4,
        name: 'Lisa Satolli',
        title: 'Project Lead / World Economic Forum',
        desc: 'Lisa is Project Lead, Humanitarian + Resilience Investing at the World Economic Forum (WEF). Previously she was Programme Manager, Private Sector Development and Innovation and a Blue Book Trainee, Cabinet of Jean-Claude Juncker at the European Commission.',
        thumbnail: lisaSmall,
        mainImg: lisaBig,
        linkedin: 'https://www.linkedin.com/in/lisasatolli/',
        twitter: '',
    },
];

export { partners, advisory };
